import { useEffect, useState } from "react"

const DraggableElement = ({ id, position, setPosition, elements }) => {
    const handleMouseDown = (e) => {
        const shiftX = e.clientX - position.x
        const shiftY = e.clientY - position.y

        const onMouseMove = (event) => {
            const newX = event.clientX - shiftX
            const newY = event.clientY - shiftY
            setPosition(id, { x: newX, y: newY })

            elements.forEach((el) => {
                if (el.id !== id) {
                    const distance = Math.sqrt(
                        Math.pow(newX - el.position.x, 2) +
                            Math.pow(newY - el.position.y, 2)
                    )
                    const minDistance = 60
                    if (distance < minDistance) {
                        const angle = Math.atan2(
                            newY - el.position.y,
                            newX - el.position.x
                        )
                        setPosition(el.id, {
                            x:
                                el.position.x -
                                Math.cos(angle) * (minDistance - distance),
                            y:
                                el.position.y -
                                Math.sin(angle) * (minDistance - distance),
                        })
                    }
                }
            })
        }

        const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove)
            document.removeEventListener("mouseup", onMouseUp)
        }

        document.addEventListener("mousemove", onMouseMove)
        document.addEventListener("mouseup", onMouseUp)
    }

    return (
        <div
            onMouseDown={handleMouseDown}
            style={{
                position: "absolute",
                width: 50,
                height: 50,
                backgroundColor: "tomato",
                borderRadius: "50%",
                cursor: "pointer",
                transform: `translate(${position.x}px, ${position.y}px)`,
            }}
        />
    )
}

const App = () => {
    const [elements, setElements] = useState([
        { id: 1, position: { x: 50, y: 50 } },
        { id: 2, position: { x: 200, y: 200 } },
    ])

    const setPosition = (id, newPosition) => {
        setElements((prev) =>
            prev.map((el) =>
                el.id === id ? { ...el, position: newPosition } : el
            )
        )
    }

    return (
        <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
            {elements.map((el) => (
                <DraggableElement
                    key={el.id}
                    id={el.id}
                    position={el.position}
                    setPosition={setPosition}
                    elements={elements}
                />
            ))}
        </div>
    )
}

export default App
